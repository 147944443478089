<template>
  <div v-if="book" class="book-card-price-info">
    <div
      v-if="+book.paid && !book.hasBook && !admin"
      v-html="priceText"
      class="book-price success--text"
    ></div>

    <div
      v-if="+book.paid && book.hasBook && !admin"
      class="book-price success--text"
    >
      <v-icon size="20" color="success">$vuetify.icons.check</v-icon>
      Куплено
    </div>

    <div
      v-if="+book.paid && book.hasBook && admin"
      class="book-price success--text"
      v-html="adminPriceText"
    ></div>

    <div v-if="!+book.paid" class="book-price success--text">Бесплатно</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["book"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
    admin() {
      if (
        this.currentUser &&
        this.book &&
        this.currentUser.id == this.book.user_id
      ) {
        return true;
      }
      return false;
    },
    priceText() {
      let text = "";
      let pre = "Подписка<br>";
      if (this.book) {
        if (+this.book.finish) {
          pre = "Цена ";
        }
        //  text = pre +  '<span class="old">151</span> 654 руб.';
        if (+this.book.final_amount < +this.book.price) {
          text =
            pre +
            ' <span class="old">' +
            this.book.price +
            "</span> " +
            this.book.final_amount +
            " руб.";
        } else {
          text = pre + " " + this.book.price + " руб.";
        }
      }

      return text;
    },
    adminPriceText() {
      let text = "";
      let pre = "Подписка<br>";
      if (this.book) {
        if (+this.book.finish) {
          pre = "Продается<br>";
        }
        //  text = pre +  '<span class="old">151</span> 654 руб.';
        if (+this.book.final_amount < +this.book.price) {
          text =
            pre +
            ' <span class="old">' +
            this.book.price +
            "</span> " +
            this.book.final_amount +
            " руб.";
        } else {
          text = pre + " " + this.book.price + " руб.";
        }
      }

      return text;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
