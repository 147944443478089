<template>
  <v-col class="text-left" cols="12">
    <v-card class="book-card">
      <div v-if="cabinet && $vuetify.breakpoint.mdAndUp" class="darg-books">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon class="drag-handle">$vuetify.icons.drag</v-icon>
            </v-btn>
          </template>
          <span>Изменить порядок</span>
        </v-tooltip>
      </div>
      <div
        class="book-long d-flex flex-no-wrap justify-start"
        :class="isMobile ? 'mobile' : ''"
      >
        <div>
          <div
            class="book-image"
            :to="{ name: 'BookShow', params: { slug: book.slug } }"
          >
            <router-link
              :to="{ name: 'BookShow', params: { slug: book.slug } }"
            >
              <img v-if="book.image_small" :src="book.image_small" />
            </router-link>
          </div>
          <book-card-price-info v-if="book && !isMobile" :book="book"></book-card-price-info>

        </div>
        <div class="book-long-body">
          <v-row>
            <v-col cols="12" sm="8">
              <!-- <div class="book-title" ><a :to="{name: 'BookShow', params: {slug: book.slug}}">{{ book.title }}</a></div> -->
              <div
                class="book-title"
                :class="isMobile ? 'mobile' : ''"
              >
                <router-link
                  :to="{ name: 'BookShow', params: { slug: book.slug } }"
                  >{{ book.title }}</router-link
                >

                <!-- <div
              class="finish"
              :class="+book.finish ? 'success--text' : 'primary--text'"
            >
              <v-icon v-if="+book.finish" dense size="14" color="success"
                >$vuetify.icons.check</v-icon
              >
              {{ +book.finish ? "Полный текст" : "В процессе" }}
            </div> -->
              </div>
              <!-- <v-btn :to="{name: 'BookShow', params: {slug: book.slug}}" text tile>Подробнее</v-btn> -->
              <div class="book-user-name">
                <router-link
                  :to="{
                    name: 'UserShow',
                    params: { userName: book.user.name },
                  }"
                  >{{ book.user.public_name }}</router-link
                >
                <span
                  v-if="book.coauthors && book.coauthors.length > 0"
                  class="book-coauthors"
                >
                  (<router-link
                    :to="{
                      name: 'UserShow',
                      params: { userName: book.coauthors[0].name },
                    }"
                    >{{ book.coauthors[0].public_name }}</router-link
                  >)
                </span>
              </div>
              <div
                v-if="!isMobile && book.substr_excerpt"
                class="book-text"
              >
                {{ book.substr_excerpt }}
              </div>
              <book-tags v-if="!isMobile" :tags="arrtags"></book-tags>
           
              <book-info
                :info="{
                  count_likes: book.count_likes,
                  count_libraries: book.count_libraries,
                  count_comments: book.count_comments,
                  last_count_comments: book.last_count_comments,
                }"
              ></book-info>
              <div class="book-card-info">
                <div
                  class="finish"
                  :class="+book.finish ? 'success--text' : 'primary--text'"
                >
                  <v-icon v-if="+book.finish" dense size="14" color="success"
                    >$vuetify.icons.check</v-icon
                  >
                  {{ +book.finish ? "Полный текст" : "В процессе" }}
                </div>
              </div>
              <div class="book-statistics mt-0">
                Общий размер:
                <span
                  >
                  {{ book.count_pages }}
                  <span v-if="!isMobile">
                  {{ "(" + book.count_char + " симв.)" }}
                  </span>
                </span>
                <span
                  v-if="!isMobile && +book.count_char > +book.last_count_char"
                  class="error--text"
                >
                  +{{ +book.count_char - +book.last_count_char }}
                </span>
              </div>
              <div
                v-if="admin"
                class="book-status"
                :class="+book.status ? 'success--text' : 'error--text'"
              >
                {{ +book.status ? "опубликована" : "черновик" }}
              </div>

              <book-card-price-info v-if="isMobile && book" class="mobile" :book="book"></book-card-price-info>

            </v-col>
            <v-col v-if="!isMobile" cols="12" sm="4">
              <book-top-info :book="book"></book-top-info>
            </v-col>
         
          </v-row>

          <!-- <div class="book-price" v-if="book.price">Цена: {{ book.price }} руб.</div> -->
          <v-card-actions class="mt-auto">
            <!-- <v-btn v-if="!admin && currentUser && book.user_id == currentUser.id" :to="{name: 'CabinetBookEdit', params: {id: book.id}}" icon>
              <v-icon>$vuetify.icons.square_edit_outline</v-icon>
            </v-btn>-->
            <v-btn v-if="cabinet && $vuetify.breakpoint.smAndDown" icon>
              <v-icon class="drag-handle">$vuetify.icons.drag</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn v-if="library" @click.stop="moveLibraryBook" icon>
              <v-icon>$vuetify.icons.cog</v-icon>
            </v-btn>
            <v-btn v-if="library" @click.stop="deleteLibraryBook" icon>
              <v-icon>$vuetify.icons.delete</v-icon>
            </v-btn>

            <v-btn
              v-if="!isMobile && !admin"
              :to="{ name: 'BookShow', params: { slug: book.slug } }"
              text
              tile
              >Подробнее</v-btn
            >
            <!-- <v-spacer v-if="admin"></v-spacer> -->
            <v-btn
              v-if="admin && !isMobile"
              :to="{
                name: 'CabinetBookEdit',
                params: { id: book.id },
                query: { tab: 'edit' },
                hash: '#status',
              }"
              text
              tile
              small
              >{{ +book.status ? "Снять с публикации" : "Опубликовать" }}</v-btn
            >
            <v-btn
              v-if="admin"
              :to="{ name: 'BookShow', params: { slug: book.slug } }"
              icon
            >
              <v-icon>$vuetify.icons.eye</v-icon>
            </v-btn>
            <v-btn
              v-if="admin"
              :to="{
                name: 'CabinetStatisticsViews',
                query: { book_id: book.id, page: 1 },
              }"
              icon
            >
              <v-icon>$vuetify.icons.poll</v-icon>
            </v-btn>
            <v-btn
              v-if="admin"
              :to="{ name: 'CabinetBookEdit', params: { id: book.id } }"
              icon
            >
              <v-icon>$vuetify.icons.square_edit_outline</v-icon>
            </v-btn>
            <v-btn v-if="admin && cabinet" @click.stop="deleteBook" icon>
              <v-icon>$vuetify.icons.delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import BookInfo from "./BookInfo.vue";
import BookTopInfo from "./BookTopInfo.vue";
import BookCardPriceInfo from "./BookCardPriceInfo.vue";
import BookTags from "./BookTags.vue";
export default {
  components: { BookInfo, BookTopInfo, BookCardPriceInfo, BookTags },
  props: ["book", "index", "library", "cabinet"],
  data() {
    return {};
  },
  methods: {
    deleteLibraryBook() {
      this.$emit("remove-library-modal", [this.book.id, "book", this.index]);
    },
    moveLibraryBook() {
      this.$emit("move-library-modal", [this.book.id, "book", this.index]);
    },
    deleteBook() {
      this.$emit("remove-book-modal", [this.book.id, this.index]);
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    arrtags() {
      let tags = this.book && this.book.tags ? this.book.tags : [];
      if (this.isMobile && tags.length > 3) {
        //Если мобильный обрезаем до трех
        return tags.slice(0, 3);
      }
      return tags;
    },
    admin() {
      if (
        this.currentUser &&
        this.book &&
        this.currentUser.id == this.book.user_id
      ) {
        return true;
      }
      return false;
    },
    priceText() {
      let text = "";
      let pre = "Подписка<br>";
      if (this.book) {
        if (+this.book.finish) {
          pre = "Цена<br>";
        }
        //  text = pre +  '<span class="old">151</span> 654 руб.';
        if (+this.book.final_amount < +this.book.price) {
          text =
            pre +
            ' <span class="old">' +
            this.book.price +
            "</span> " +
            this.book.final_amount +
            " руб.";
        } else {
          text = pre + " " + this.book.price + " руб.";
        }
      }

      return text;
    },
    adminPriceText() {
      let text = "";
      let pre = "Подписка<br>";
      if (this.book) {
        if (+this.book.finish) {
          pre = "Продается<br>";
        }
        //  text = pre +  '<span class="old">151</span> 654 руб.';
        if (+this.book.final_amount < +this.book.price) {
          text =
            pre +
            ' <span class="old">' +
            this.book.price +
            "</span> " +
            this.book.final_amount +
            " руб.";
        } else {
          text = pre + " " + this.book.price + " руб.";
        }
      }

      return text;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
